
import { defineComponent, ref, computed } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useGettext } from "vue3-gettext";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";

export default defineComponent({
  name: "sign-in",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  data() {
    const gettext = useGettext();

    const curLang = localStorage.getItem("lang")
      ? (localStorage.getItem("lang") as string)
      : "en";
    gettext.current = curLang;
    return {
      curLang,
    };
  },
  setup() {
    const router = useRouter();
    const gettext = useGettext();
    const store = useStore();

    const countries = {
      en: {
        flag: "media/flags/united-states.svg",
        name: "English",
        value: "en",
      },
      sl: {
        flag: "media/flags/slovenia.svg",
        name: "Slovenščina",
        value: "sl",
      },
    };
    const submitButton = ref<HTMLElement | null>(null);

    //Create form validation object
    const login = Yup.object().shape({
      destination: Yup.string()
        .required()
        .email()
        .label(gettext.$gettext("Email")),
      /*email: Yup.string().required().email().label(gettext.$gettext("Email")),
      password: Yup.string()
        .min(4)
        .required()
        .label(gettext.$gettext("Password")),*/
    });

    //Form submit function
    const onSubmitLogin = (values) => {
      // Clear existing errors
      store.dispatch(Actions.LOGOUT);

      // Activate indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");
      submitButton.value?.setAttribute("disabled", "true");
      // Dummy delay
      setTimeout(() => {
        // Send login request
        store
          .dispatch(Actions.LOGIN, values)
          .then(() => {
            /*Swal.fire({
              text: gettext.$gettext("Successful login!"),
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: gettext.$gettext("Ok, got it!"),
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(() => {*/
            // Go to page after successfully login
            submitButton.value?.removeAttribute("data-kt-indicator");
            router.push({ name: "check" });
            //});
          })
          .catch(() => {
            Swal.fire({
              text: store.getters.getErrors,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: gettext.$gettext("Try again!"),
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
            submitButton.value?.removeAttribute("data-kt-indicator");
            submitButton.value?.removeAttribute("disabled");
          });

        //Deactivate indicator
      }, 100);
    };

    const setLang = (lang) => {
      localStorage.setItem("lang", lang);
      gettext.current = lang;
      store.dispatch(Actions.LANGUAGE_CHANGED);
    };

    const currentLanguage = (lang) => {
      return gettext.current === lang;
    };

    const currentLangugeLocale = computed(() => {
      return countries[gettext.current];
    });

    return {
      submitButton,
      onSubmitLogin,
      login,
      setLang,
      currentLanguage,
      currentLangugeLocale,
      countries,
    };
  },
});
